::-webkit-scrollbar {
    width: 0px;
}

body {
    margin: 0;
    padding: 0;
    color: #e7e7e7;
    /* background-color: black; */
    font-family: "Patua One", serif;
    /* font-family: "Nunito", sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: larger;
    background-color: black;
    background-image: linear-gradient(to bottom, #000000, #19010d, #24031a, #2b0429, #2e053c);
    /* background-image: linear-gradient(to bottom, #82439c, #613474, #41254d, #24172a, #030004); */
    /* background-image: linear-gradient(to bottom, #957f9e, #6e5e75, #4a3f4e, #28222a, #030004); */
    /* background-image: linear-gradient(to bottom, #020024, #090979, #940d56, #860519, black); */
    /* background-image:
        radial-gradient(at 47% 33%, hsl(259.47, 77%, 40%) 0, transparent 28%),
        radial-gradient(at 82% 65%, hsla(309, 31%, 31%, 0.934) 0, transparent 55%) !important; */
    /* radial-gradient(circle at 90% 90%, hsla(0, 0%, 0%, 0.934) 0, black 2%); */
    /* filter: blur(15px); */
    /* backdrop-filter: blur(15px); */
    /* overflow: hidden; */

}

.scroller {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 40px;
}

.UCI-Ed {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    height: 90vh;
    flex: 1;
    /* margin-left: 20px;
    margin-right: 20px; */
}

.DA-Ed {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    height: 50vh;
    flex: 1;
}