/* body {
    background: #0b1b2a;
    margin: 0;
    padding: 0;
  }
   */
  .wrapper {
    /* height: 100vh; */
    max-width: 1000px;
    margin: 0 auto;
    padding: calc(30vw / 2 - 3px) 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  .row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: calc(30vw * 0.95 - 36px);
  }
  .row:nth-child(2), .row:nth-child(3), .row:nth-child(4), .row:nth-child(5), .row:nth-child(6), .row:nth-child(7) {
    margin-top: calc(-30vw / 2 + 18px);
  }
  .row:nth-child(4) {
    flex-flow: column nowrap;
    gap: 0px;
  }
  .row:nth-child(4) .hex:nth-child(1),
  .row:nth-child(4) .hex:nth-child(3) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .row:nth-child(4) .hex:nth-child(1) {
    top: 0;
  }
  .row:nth-child(4) .hex:nth-child(3) {
    bottom: 0;
  }
  
  .hex {
    height: calc(30vw - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .hex .hexLeft {
    transform: rotate(120deg);
    height: 100%;
    width: 30vw;
    overflow: hidden;
  }
  .hex .hexLeft .hexRight {
    transform: rotate(-60deg);
    height: 100%;
    width: inherit;
    overflow: hidden;
  }
  .hex .hexLeft .hexRight .info {
    transform: rotate(-60deg);
    height: 100%;
    width: 100%;
    position: relative;
    visibility: visible;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .hex .hexLeft .hexRight .info .name {
    max-width: calc(100% - 50px);
    color: #fff;
    /* font-family: Barlow Semi Condensed; */
    font-size: clamp(12px, 3vw,24px);
    font-weight: 800;
    letter-spacing: 0.72px;
  }
  .hex .hexLeft .hexRight .info .title {
    color: #72ede1;
    /* font-family: Barlow Semi Condensed; */
    font-size: clamp(10px, 2.2vw, 16px);
    font-weight: 900;
    letter-spacing: 1.12px;
  }
  .hex .hexLeft .hexRight .info .dept {
    color: #fff;
    /* font-family: Barlow Semi Condensed; */
    font-size: clamp(10px, 2vw, 16px);
    font-style: normal;
    font-weight: 600;
  }
  .hex .hexLeft .hexRight .info .links {
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    gap: 6px;
    align-items: center;
    justify-content: center;
  }
  .hex .hexLeft .hexRight .info .links a {
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 26px;
    gap: 4px;
    border-radius: 4px;
    background: #72ede1;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  }
  .hex .hexLeft .hexRight .info .links a span {
    color: #282f38;
    /* font-family: Manrope; */
    font-size: clamp(8px, 1.8vw,14px);
    font-weight: 700;
    letter-spacing: 0.14px;
  }
  .hex .hexLeft .hexRight .avatar {
    cursor: pointer;
    transform: rotate(-60deg);
    height: 100%;
    width: 100%;
    position: relative;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hex .hexLeft .hexRight .avatar::before, .hex .hexLeft .hexRight .avatar::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .hex .hexLeft .hexRight .avatar::before {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="208" height="184" viewBox="0 0 225 199" fill="none"><path d="M51.8104 8.96145L51.8113 8.95985C54.4609 4.35806 59.3704 1.52563 64.6868 1.52563L160.582 1.52564C165.898 1.52564 170.808 4.35806 173.457 8.95986L173.458 8.96146L221.404 92.0001C224.056 96.6049 224.056 102.27 221.404 106.875L173.458 189.913L173.457 189.915C170.808 194.517 165.898 197.349 160.582 197.349H64.6868C59.3704 197.349 54.4608 194.517 51.8113 189.915L51.8104 189.913L3.86392 106.875C3.86376 106.874 3.86359 106.874 3.86343 106.874C1.21284 102.27 1.21284 96.6053 3.86343 92.0009C3.86359 92.0006 3.86376 92.0004 3.86393 92.0001L51.8104 8.96145Z" stroke="%230B1B2A" stroke-width="12"/></svg>') center no-repeat;
  }
  .hex .hexLeft .hexRight .avatar::after {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="208" height="184" viewBox="0 0 225 199" fill="none"><path d="M51.8104 8.96145L51.8113 8.95985C54.4609 4.35806 59.3704 1.52563 64.6868 1.52563L160.582 1.52564C165.898 1.52564 170.808 4.35806 173.457 8.95986L173.458 8.96146L221.404 92.0001C224.056 96.6049 224.056 102.27 221.404 106.875L173.458 189.913L173.457 189.915C170.808 194.517 165.898 197.349 160.582 197.349H64.6868C59.3704 197.349 54.4608 194.517 51.8113 189.915L51.8104 189.913L3.86392 106.875C3.86376 106.874 3.86359 106.874 3.86343 106.874C1.21284 102.27 1.21284 96.6053 3.86343 92.0009C3.86359 92.0006 3.86376 92.0004 3.86393 92.0001L51.8104 8.96145Z" stroke="%2372EDE1" stroke-width="4"/></svg>') center no-repeat;
    opacity: 0;
    transition: all 0.2s ease;
  }
  .hex .hexLeft .hexRight .avatar img {
    width: 100%;
    height: auto;
    filter: brightness(70%) grayscale(1);
  }
  .hex .hexLeft .hexRight .avatar:hover::before, .hex .hexLeft .hexRight .avatar:hover::after, .hex .hexLeft .hexRight .avatar.active::before, .hex .hexLeft .hexRight .avatar.active::after {
    opacity: 1;
  }
  .hex .hexLeft .hexRight .avatar:hover img, .hex .hexLeft .hexRight .avatar.active img {
    filter: brightness(100%) grayscale(0);
  }
  
  /* RESPONSIVE */
  @media screen and (min-width: 600px) {
    .wrapper {
      padding: 94px 20px;
    }
  
    .row {
      gap: 120px;
    }
    .row:nth-child(2), .row:nth-child(3), .row:nth-child(4), .row:nth-child(5), .row:nth-child(6), .row:nth-child(7) {
      margin-top: -88px;
    }
    .row:nth-child(4) {
      flex-flow: column nowrap;
      gap: 0px;
    }
    .row:nth-child(4) .hex:nth-child(1),
  .row:nth-child(4) .hex:nth-child(3) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .row:nth-child(4) .hex:nth-child(1) {
      top: 0;
    }
    .row:nth-child(4) .hex:nth-child(3) {
      bottom: 0;
    }
    .row .hex {
      height: 184px;
    }
    .row .hex .hexLeft {
      width: 208px;
    }
  }
  @media screen and (max-width: 10000px) {
    .wrapper {
      padding: 20px;
    }
  
    .row:nth-child(4) {
      flex-flow: row nowrap;
      gap: 120px;
    }
    .row:nth-child(4) .hex:nth-child(1),
  .row:nth-child(4) .hex:nth-child(3) {
      position: unset;
      transform: unset;
      left: unset;
    }
  }