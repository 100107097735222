@-webkit-keyframes animation {
    from {
        -webkit-transform: translateZ(500px) translateY(-100px) translateX(100px) rotate(360deg);
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes animation {
    from {
        transform: translateZ(500px) translateY(-100px) translateX(100px) rotate(360deg);
        opacity: 0;
    }

    to {
        transform: translateZ(0);
        opacity: 1;
    }
}

:root {
    --block-row-width: 600px;
    --block-row-height: 600px;
    --line-height: 200px;
}

@media screen and (max-width: 600px) {
    :root {
        --block-row-width: 330px;
        --block-row-height: var(--block-row-width);
        --line-height: calc(var(--block-row-width)/3);
    }

    .tiles-ul>li {
        font-size: 11px;
    }

    .tiles-ul>li:nth-child(1){
        font-size: 14px !important;
    }
}

.line-wrap-section {
    margin: 0px;
    height: calc(var(--block-row-height)/6);
    width: calc(var(--block-row-width)/3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-tiles-wrapper {
    display: flex;
    /* width: 100%;
    height: 100%; */
    justify-content: center;
    align-items: center;
    position: relative;
}

.tiles-ul {
    display: block;
    padding: 0;
    width: var(--block-row-width);
    height: var(--block-row-height);
    /* position: absolute; */
    /* left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto; */
    -webkit-perspective: 100px;
    -moz-perspective: 100px;
    perspective: 100px;
}

.tiles-ul>li {
    display: block;
    width: calc(var(--block-row-width)/3);
    height: calc(var(--block-row-height)/3);
    float: left;
    opacity: 0;
    line-height: var(--line-height);
    text-align: center;
    font-weight: bold;
    /* word-break: break-all; */
    box-shadow: 0 0 10px black;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.leaf-flex {
    display: flex;
    height: calc(var(--block-row-height)/3);
    width: calc(var(--block-row-width)/3);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tiles-ul>li:nth-child(1) {
    background-color: #ffbc73;
    color: black;
    width: calc(2 * var(--block-row-width)/3);
    border-radius: 5px 0 0 0;
}

.tiles-ul>li:nth-child(2) {
    display: inline-block;
    background-color: #54c23598;
    color: white;
    font-weight: 200;
    overflow-wrap: break-word;
    border-radius: 0 5px 0 0;
}

.tiles-ul>li:nth-child(3) {
    background-color: #ff8400d5;
    color: white;
    font-weight: 200;
}

.tiles-ul>li:nth-child(4) {
    background-color: #a67600;
    color: black
}

.tiles-ul>li:nth-child(5) {
    background-color: #bf9630;
    color: white;
    font-weight: 200;
}

.tiles-ul>li:nth-child(6) {
    background-color: #985d1e;
    color: #ffde8b;
    border-radius: 0 0 0 5px;
}

.tiles-ul>li:nth-child(7) {
    background-color: rgb(40 40 40);
    color: #f47155;
    width: calc(2 * var(--block-row-width)/3);
    border-radius: 0 0 5px 0;
}

.tiles-ul>li:nth-child(8) {
    background-color: #bf4930;
    color: #e89c8c
}

.tiles-ul>li:nth-child(9) {
    background-color: #ff2c00;
    color: #ff937c
}

#list .leaf:nth-child(5) {
    animation: 1s 0s ease animation;
    -webkit-animation: 1s 0s ease animation;
    -moz-animation: 1s 0s ease animation;
    -o-animation: 1s 0s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(6) {
    animation: 1s .4375s ease animation;
    -webkit-animation: 1s .4375s ease animation;
    -moz-animation: 1s .4375s ease animation;
    -o-animation: 1s .4375s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(7) {
    animation: 1s .75s ease animation;
    -webkit-animation: 1s .75s ease animation;
    -moz-animation: 1s .75s ease animation;
    -o-animation: 1s .75s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(8) {
    animation: 1s .9375s ease animation;
    -webkit-animation: 1s .9375s ease animation;
    -moz-animation: 1s .9375s ease animation;
    -o-animation: 1s .9375s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(9) {
    animation: 1s 1s ease animation;
    -webkit-animation: 1s 1s ease animation;
    -moz-animation: 1s 1s ease animation;
    -o-animation: 1s 1s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(5) {
    animation: 1s 0s ease animation;
    -webkit-animation: 1s 0s ease animation;
    -moz-animation: 1s 0s ease animation;
    -o-animation: 1s 0s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(4) {
    animation: 1s .4375s ease animation;
    -webkit-animation: 1s .4375s ease animation;
    -moz-animation: 1s .4375s ease animation;
    -o-animation: 1s .4375s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(3) {
    animation: 1s .75s ease animation;
    -webkit-animation: 1s .75s ease animation;
    -moz-animation: 1s .75s ease animation;
    -o-animation: 1s .75s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(2) {
    animation: 1s .9375s ease animation;
    -webkit-animation: 1s .9375s ease animation;
    -moz-animation: 1s .9375s ease animation;
    -o-animation: 1s .9375s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

#list .leaf:nth-child(1) {
    animation: 1s 1s ease animation;
    -webkit-animation: 1s 1s ease animation;
    -moz-animation: 1s 1s ease animation;
    -o-animation: 1s 1s ease animation;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards
}

.red-icon-tiles{
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #F5544D;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    position: absolute;
    top: 28px;
    left: 10px;
    z-index: 100;
    display: none;
}