.exp-container {
  letter-spacing: 0.05em;
  /* height: 80vh; */
}

.btn {
  display: inline-block;
  padding: 13px 20px;
  color: #fff;
  text-decoration: none;
  position: relative;
  background: transparent;
  border: 1px solid #e1e1e1;
  font: 12px/1.2 "Oswald", sans-serif;
  letter-spacing: 0.4em;
  text-align: center;
  text-indent: 2px;
  text-transform: uppercase;
  transition: color 0.1s linear 0.05s;
}

.btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  z-index: 1;
  opacity: 0;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
}

.btn::after {
  transition: border 0.1s linear 0.05s;
}

.btn .btn-inner {
  position: relative;
  z-index: 2;
}

.btn:hover {
  color: #373737;
  transition: color 0.1s linear 0s;
}

.btn:hover::before {
  top: 0;
  height: 100%;
  opacity: 1;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
}

.btn:hover::after {
  border-color: #373737;
  transition: border 0.1s linear 0s;
}

.slideshow {
  overflow: hidden;
  /* margin-left: 20px;
  margin-right: 40px; */
  position: relative;
  width: 100%;
  height: 80vh;
  z-index: 1;
  border-radius: 10px;
}

.slideshow .slideshow-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slideshow .slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slideshow .slide {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.slideshow .slide.is-active {
  display: block;
}

.slideshow .slide.is-loaded {
  opacity: 1;
}

.slideshow .slide .caption {
  padding: 0 100px;
}

.slideshow .slide .image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  z-index: 1;
  background-size: cover;
  image-rendering: optimizeQuality;
}

.slideshow .slide .image-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.slideshow .slide .image {
  width: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: 30% 30%;
}

.slideshow .slide-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow .slide .title {
  margin: 0 auto 15px;
  max-width: 1000px;
  font: 200 35px/1.2 "Oswald", sans-serif;
  letter-spacing: 0.35em;
  text-transform: uppercase;
}


.slideshow .slide .text {
  margin: 0 auto;
  max-width: 1000px;
  font-size: 18px;
  line-height: 1.4;
}

.slideshow .slide .btn {
  margin: 15px 0 0;
  border-color: #fff;
}

.slideshow .slide .btn::before {
  background: #fff;
}

.slideshow .pagination {
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;
  height: 12px;
  cursor: default;
  z-index: 2;
  text-align: center;
}

.slideshow .pagination .item {
  display: inline-block;
  padding: 15px 5px;
  position: relative;
  width: 46px;
  height: 32px;
  cursor: pointer;
  text-indent: -999em;
  z-index: 1;
}

.slideshow .pagination .item+.page {
  margin-left: -2px;
}

.slideshow .pagination .item::before {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: 5px;
  width: 36px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  transition: background 0.2s ease;
}

.slideshow .pagination .item::after {
  width: 0;
  background: #fff;
  z-index: 2;
  transition: width 0.2s ease;
}

.slideshow .pagination .item:hover::before,
.slideshow .pagination .item.is-active::before {
  background-color: #fff;
}

.slideshow .arrows .arrow {
  margin: -33px 0 0;
  padding: 20px;
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 3;
}

@media screen and (max-width: 500px) {
  .slideshow .arrows .arrow {
    top: 80%;
  }
}

.slideshow .arrows .prev {
  left: 30px;
}

.slideshow .arrows .prev:hover .svg {
  left: -10px;
}

.slideshow .arrows .next {
  right: 30px;
}

.slideshow .arrows .next:hover .svg {
  left: 10px;
}

.slideshow .arrows .svg {
  position: relative;
  left: 0;
  width: 14px;
  height: 26px;
  fill: #fff;
  transition: left 0.2s ease;
}


.ed-intro {
  display: flex;
  margin-left: 20px;
  font-size: 40px;
  align-items: center;
  padding-top: 20px;
  /* justify-content: space-around; */
}

.school-logo-container {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  background-color: gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.school-logo {
  /* margin-right: 20px; */
  padding: 0;
}

.ed-line {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 18px;
  color: #8ba2a3;
}

.experience-outer-container {
  /* height: 100vh; */
  margin: 40px;
  margin-bottom: 0px;
}

.main-content {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 1100px) {
  .main-content {
    padding: 0px;
  }

  .experience-outer-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}