/* Background styles */
.GM-container {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 40px;
    padding-left: 20px;
    padding-right: 20px;
    /* height: 100vh; */
    /* background-color: black; */
    /* background-image:
        radial-gradient(at 47% 33%, hsl(259.47, 77%, 40%) 0, transparent 28%),
        radial-gradient(at 82% 65%, hsla(309, 31%, 31%, 0.934) 0, transparent 55%),
        radial-gradient(circle at 90% 90%, hsla(0, 0%, 0%, 0.934) 0, black 2%); */
}

/* Glassmorphism card effect */
.GM-card {
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    margin: 10px;
    padding: 40px;
    padding-left: 20px;
    padding-right: 20px;
    height: 80%;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    font-weight: 300;
    font-family: "Nunito", sans-serif;
}

.ed-intro {
    display: flex;
    margin-left: 20px;
    font-size: 40px;
    align-items: center;
    /* justify-content: space-around; */
}

.ed-line {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 18px;
    color: #8ba2a3;
}

.school-logo-container {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background-color: gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.school-logo {
    /* margin-right: 20px; */
    padding: 0;
}

/* Generated by https://generator.ui.glass/ */